import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "../../components/contact/Contact";

function ContactPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>Contact Us | Adam Click</title>
        <meta name="keyword" content="Adam Click" />
        <meta name="description" content="Adam Click" />
      </Helmet>
      <Contact />
    </>
  );
}

export default ContactPage;
